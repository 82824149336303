import { render, staticRenderFns } from "./ProjectHoldList.vue?vue&type=template&id=38e9dddc&scoped=true&"
import script from "./ProjectHoldList.vue?vue&type=script&lang=js&"
export * from "./ProjectHoldList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectHoldList.vue?vue&type=style&index=0&id=38e9dddc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e9dddc",
  null
  
)

export default component.exports